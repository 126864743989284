import Vue from 'vue';
import {
    MessengerElement,
    MessengerActionButtonElement,
    PaymentMethodsElement,
    SubscriptionsElement,
    SubscriptionPromoCheckoutElement,
} from '@lib/plugins/dist/main.es.js';

if (process.client) {
    customElements.define('ce-messenger', MessengerElement);
    customElements.define('ce-messenger-action-button', MessengerActionButtonElement);
    customElements.define('ce-payment-methods', PaymentMethodsElement);
    customElements.define('ce-subscriptions', SubscriptionsElement);
    customElements.define('ce-subscription-promo-checkout', SubscriptionPromoCheckoutElement);
}

Vue.config.ignoredElements = [/^ce-/];
