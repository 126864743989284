export default async function (ctx) {
    let { redirect, $auth, route, query: { auth_token }, store } = ctx;

    // Extend $auth with a safe token getter if not already defined
    if (!$auth.getSafeToken) {
        $auth.getSafeToken = function () {
            try {
                const authValue = this.strategy.token.get() || '';
                // The stored token includes a "Bearer" prefix; extract the bare token
                const parts = authValue.split(/\s+/);
                return parts.length > 1 ? parts[1] : '';
            } catch (error) {
                return '';
            }
        };
    }

    store.commit('SET', { favorite_properties: $auth.user?.favourite_properties ?? [] });
    $auth.$storage.watchState('loggedIn', () => {
        store.commit('SET', { favorite_properties: $auth.user?.favourite_properties ?? [] });
    });

    if (auth_token) {
        let redirect_param = $auth.options.redirect;
        $auth.options.redirect = false;
        try {
            await $auth.loginWith('token', { data: { auth_token } });
            delete route.query.auth_token;
            redirect(route);
            ctx.res = null;
        } catch (error) {
            redirect({ name: 'login', query: { error_message: error } });
            ctx.res = null;
        } finally {
            $auth.options.redirect = redirect_param;
        }
    }
}
