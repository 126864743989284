import { render, staticRenderFns } from "./header.vue?vue&type=template&id=6c220c59&scoped=true"
import script from "./header.vue?vue&type=script&lang=js"
export * from "./header.vue?vue&type=script&lang=js"
import style0 from "./header.vue?vue&type=style&index=0&id=6c220c59&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c220c59",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppNuxtLink: require('/app/components/app/nuxt-link.vue').default})
