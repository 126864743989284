
const
    URL_APP_STORE = 'https://apps.apple.com/do/app/investorlift/id6503916741',
    URL_PLAY_STORE = '',
    isAndroid = () => /Android/i.test(navigator.userAgent),
    isIos = () => /iPhone|iPad|iPod/i.test(navigator.userAgent),
    getAppUrl = () => {
        if(isAndroid()) return URL_PLAY_STORE;
        if(isIos()) return URL_APP_STORE;
        return null;
    }

export default {
    props: {
        rating: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            show: false,
            url: null
        }
    },
    computed: {
        cssProps() {
            return {
                '--rating': `${this.rating}`
            }
        },
        dismissed() {
            return this.$store.state.settings.mobile_overlay_dismissed;
        },
    },
    watch: {
        dismissed(newValue) {
            this.show = !newValue;
        },
    },
    mounted() {

        if (process.client) {
            const isMobile = isAndroid() || isIos();
            this.url = getAppUrl();
            this.show = isMobile && !this.dismissed && this.url;
        }

    },
    methods: {
        dismiss() {
            this.$store.commit('settings/SET_MOBILE_OVERLAY_DISMISSED', true )
        }
    },
    filters: {
        formatRating(v) {
            return v.toFixed(1);
        }
    }
}
