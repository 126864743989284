let settings = {
    socialAuthIframeBaseUrl: '/widget/buyer/auth/social',
    API: {
        actionVerificationCode: 'auth/action-verification-code',
        socialLogin: 'auth/socialLogin',
        register: 'auth/register',
        recoveryPasswordEmail: 'auth/recovery-password-email',
        newPassword: 'auth/change-password',
        changePassword: 'auth/update-password',
        phone: 'phone',
        entityPhoneById: id => `entity-phone/${id}`,
        togglePhoneOptedIn: 'phone/togglePhoneOptedIn',
        getConfirmationSmsCode: 'phone/getConfirmationSmsCode',
        confirmPhoneBySmsCode: 'phone/confirmPhoneBySmsCode',
        updateProfile: 'auth/update-profile',
        updateAvatar: 'auth/update-avatar',
        unsubscribe: 'auth/unsubscribe',
        attachToAccount: 'auth/attach-to-account',
        userGeotags: 'auth/geotags',
        userProperty: 'auth/property',
        userAcceptTerms: 'auth/accept-terms',
        states: 'states',
        propertyType: 'property-type',
        parkingType: 'parking-type',
        properties: 'properties',
        propertyById: id => `properties/${id}`,
        neighborhoodById: id => `/properties/${id}/neighborhood`,
        propertyOpenHouseById: id => `/open-houses/${id}`,
        prePostDealAddress: '/properties/pre-post/address',
        prePostDealPrice: '/properties/pre-post/price',
        prePostDealDetails: '/properties/pre-post/details',
        inquiry: 'inquiry',
        propertyOffer: id => `properties/${id}/offer`,
        propertyAddressRequest: 'property-address-request',
        favouriteProperties: '/properties/favourites',
        removeFavouriteProperty: id => `/properties/favourites/${id}`,
        customerGeotagsAllDelete: '/customer/geotags/all-delete',
        offer: 'offer',
        createOfferSimple: 'create-offer-simple',
        receivedOffer: 'received-offer',
        receivedOfferById: id => `received-offer/${id}`,
        openHouseRegistration: 'open-house-registration',
        offerById: id => `offer/${id}`,
        offerByIdConfirm: id => `offer/${id}/confirm`,
        proofOfFunds: 'proof-of-funds',
        proofOfFundsDownload: pofId => `proof-of-funds/${pofId}/download`,
        corporateDocs: id => `offers/${id}/corporate-documents`,
        downloadOfferCorporateDocuments: (offerId, documentId) => `offers/${offerId}/corporate-documents/${documentId}/download`,
        dialogsStatus: '/dialogs/status',
        dialog: '/dialog',
        dialogById: id => `/dialog/${id}`,
        dialogMessage: '/dialog-message',
        dialogMessageRead: '/dialog-message/read',
        registerTrialAccountAndUser: 'register/registerTrialAccountAndUser',
        loanLead: '/loan-lead',
        entityNote: '/entity-note',
        accountRating: id => `account-stats/${id}/rating`,
        getDocument: '../../api/document',

        ui_events: '/ui-events'
    },
    dicts: {
        price: [0, 'Any'],
        propertyStatus: {
            available: 'available',
            pending: 'pending',
            sold: 'sold'
        }
    },
    propertyMapZoom: 14,
    proofOfFundsValidDays: 30,
    proofOfFundsMaxFileSize: 10 * 1024 * 1024,
    corpDocsMaxFileSize: 10 * 1024 * 1024,
    avatarMaxFileSize: 1 * 1024 * 1024,
    defaultMapCenter: [
        37.6644943,
        -97.6084446,
        4
    ],
    postDeal: {
        searchThreshold: 300
    },
    corporateDoucmentType: {
        proof_of_funds: 'proof_of_funds',
    },
    premiumBuyerSlug: 'deals-vip',
};

export default (context, inject) => {
    inject('settings', settings);
}

