
    import Mixins from '~/plugins/app_mixins'

    export default {
        // login_mode
        props: ['value'],
        mixins: [Mixins.socialAuth],
        data() {
            return {
                email: '',
                password: '',
            }
        },
        watch: {
            value() {
                this.clear_errors();
            },
        },
        mounted() {
            window.addEventListener("message", this.listenerMessage);
        },
        destroyed() {
            window.removeEventListener("message", this.listenerMessage);
        },
        methods: {
	        async login() {
                grecaptcha.enterprise.ready(async () => {
                    const token = await grecaptcha.enterprise.execute(this.$config.google_recaptcha_key, {action: 'LOGIN'});
                    let {login_mode, ...data} = this.$data;
                    let redirect = this.$auth.options.redirect;
                    this.$auth.options.redirect = false;
                    try {
                        await this.$auth.loginWith('local', {
                            data: {
                                ...data,
                                recaptcha_token: token
                            },
                        });
                        if (!this.$auth.loggedIn) {
                            window.location.reload();
                        }
                        this.$bvModal.hide('modal-login');
                        this.fetchIsPremiumBuyer();
                    } catch (e) {
                        if (e?.response?.data?.action === 'verify') {
                            await this.verifyLogin(e?.response?.data?.customer_id)
                        } else {
                            throw e;
                        }
                    } finally {
                        this.$auth.options.redirect = redirect;
                    }
                });
	        },
            async fetchIsPremiumBuyer() {
                if (!this.$auth.loggedIn) return;

                try {
                    let isPremium;

                    const activeSubscriptions = await this.$axios.$get(this.$settings.API.getDocument,
                        {params: {with: 'operation', 'filter[mode]': 'my_subscriptions'}}
                    );

                    isPremium = activeSubscriptions.some(
                        subscription => subscription.operation.slug === this.$settings.premiumBuyerSlug
                    );

                    this.$store.dispatch('setIsPremium', isPremium);
                } catch (e) {
                }
            },
            listenerMessage(event) {
	            if (event.origin !== this.getBackendOrigin() || !event.data) return;
                this.socialLogin(event.data);
            },
            async socialLogin(data) {
                let redirect = this.$auth.options.redirect;
                this.$auth.options.redirect = false;
                try {
                    await this.$auth.loginWith('social_login', {data});
                    this.$bvModal.hide('modal-login');
                    this.fetchIsPremiumBuyer();
                } finally {
                    this.$auth.options.redirect = redirect;
                }
            },
            async verifyLogin(customer_id) {
                this.$nuxt.$emit('open_modal_verify_action', {
                    action: 'login',
                    customer_id: customer_id,
                    onVerify: async (verification_hash) => {
                        let redirect = this.$auth.options.redirect;
                        this.$auth.options.redirect = false;
                        try {
                            await this.$auth.loginWith('local', {data: {
                                email: this.email,
                                password: this.password,
                                verification_hash: verification_hash
                            }});
                            this.$bvModal.hide('modal-login');
                        } finally {
                            this.$auth.options.redirect = redirect;
                        }
                    }
                })
            }
        }
    }
