export const state = () => ({
    session_counter: 0,
    client_session_id: null,
    mobile_overlay_dismissed: false,
});

export const mutations = {
    SET_SESSION: (state, value) => {
        state.client_session_id = value;
    },
    SET_SESSION_COUNTER: (state, value) => {
        state.session_counter = value;
    },
    SET_MOBILE_OVERLAY_DISMISSED: (state, value) => {
        state.mobile_overlay_dismissed = value;
        if (!value) state.session_counter = 0;
    }
};


export const actions = {
    initState({ state, commit }, client_session_id) {

        const saved_id = state.client_session_id;
        const current_id = client_session_id;

        commit('SET_SESSION', current_id);

        if (saved_id && saved_id !== current_id) {
            commit('SET_SESSION_COUNTER', state.session_counter + 1);
        }
        if (!saved_id || state.session_counter === 10) {
            commit('SET_MOBILE_OVERLAY_DISMISSED', false);
        }

    },
};
