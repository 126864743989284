import { render, staticRenderFns } from "./default.vue?vue&type=template&id=3b5b6120"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeader: require('/app/components/app/header.vue').default,AppTitle: require('/app/components/app/title.vue').default,AppFooter: require('/app/components/app/footer.vue').default,AppErrorMessage: require('/app/components/app/error-message.vue').default,UserRequestDetails: require('/app/components/user/request-details.vue').default,PostDealModal: require('/app/components/post-deal/modal.vue').default,AppLogin: require('/app/components/app/login.vue').default,ModalVerifyAction: require('/app/components/modal/verify-action.vue').default,ModalConfirmPhone: require('/app/components/modal/confirm-phone.vue').default,AppMobileOverlay: require('/app/components/app/mobile-overlay.vue').default})
