export const state = () => ({
    meta: {
        title: null,
        breadcrumbs: [],
        titlebar: true,
        footer: true
    },
    app: {
        states: [],
        properties: {
            img_paths: {},
            columns: [],
            data: [],
            all_count: 0,
        },
        properties_full: {},
        favorite_properties: [],
        isPremiumBuyer: false,
    },
});

export const getters = {
    properties: state => {
        let properties_map = state.app.properties.data
            ?.map(row => {
                row = row.reduce((obj_row, val, i) => {
                    obj_row[state.app.properties.columns[i]] = val;
                    return obj_row;
                }, {});

                let thumbnail_filename = row.img_filename.split('.');
                thumbnail_filename[thumbnail_filename.length - 2] += '-th1.' + thumbnail_filename[thumbnail_filename.length - 1];
                thumbnail_filename.pop();
                row.main_photo = state.app.properties.img_paths[row.img_path_id] + '/' + thumbnail_filename.join('.');
                row.main_photo_full = state.app.properties.img_paths[row.img_path_id] + '/' + row.img_filename;
                row.images = [];

                row.status = ['available', 'pending', 'sold'][row.status_index - 2];
                row.slug = [row.county, row.city, row.state_code, row.zip]
                    .filter(Boolean)
                    .join('-')
                    .toLowerCase()
                    .replace(/[^a-z0-9]+/g, '-')
                ;

                if (row.main_photo_full) row.images.push({url: row.main_photo_full});

                return row;
            }) ?? [];

        for (const id of Object.keys(state.app.properties_full)) {
            let index = properties_map.findIndex(r => r.id === Number(id));

            if (index === -1) {
                properties_map.push(state.app.properties_full[id]);
            } else {
                properties_map[index] = {...properties_map[index], ...state.app.properties_full[id]};
            }
        }

        properties_map = properties_map.map(row => {
            row = {...row};
            row.is_favorite = state.app.favorite_properties?.includes?.(row.id);

            return row;
        }) ?? [];

        return properties_map;
    },
    all_count: state => state.app.properties.all_count,
    properties_by_id: (state, getters) => {
        return getters.properties.reduce((res, r) => {
            res[r.id] = r;
            return res;
        }, {});
    },
    properties_full: state => state.app.properties_full,
    states: state => state.app.states,
    isPremiumBuyer: state => state.app.isPremiumBuyer,
};

export const mutations = {
    set_meta: (state, meta) => {
        state.meta = meta;
    },
    SET: (state, data) => {
        state.app = {...state.app, ...data};
    },
    SET_PROPERTY_FULL: (state, data) => {
        state.app.properties_full[data.id] = data;
        state.app.properties_full = {...state.app.properties_full};
    },
    INCREASE_PROPERTY_PAGE_VIEW: (state, property_id) => {
        let property = state.app.properties_full[property_id];
        if (property) {
            state.app.properties_full[property_id] = {...property, views: ++property.views};
            state.app.properties_full = {...state.app.properties_full};
        }
    },
};

export const actions = {
    nuxtServerInit({ commit }, { res }) {
        const client_session_id = res?.locals?.client_session_id || null;
        commit('settings/SET_SESSION', client_session_id);
    },
    async load_properties({commit, state, getters}, params = {}) {
        let account_slug;

        if (this.$config.cur_site_config.account.is_super) {
            ({account_slug} = params);
        } else {
            account_slug = this.$config.cur_site_config.account.slug;
        }

        let properties = await this.$api('properties/select', {
            ...params,
            account_slug
        }, {loader: 'properties'})

        if (properties
            && (!state.app.properties.data.length
                || JSON.stringify(properties) !== JSON.stringify(state.app.properties)
            )
        ) {
            commit('SET', {properties});
        }
    },
    async toggleFavorite({commit}, {property_id, add}) {
        let favorite_properties;

        if (add) {
            favorite_properties = await this.$axios
                .$post(this.$settings.API.favouriteProperties,{property_id})
            ;
        } else {
            favorite_properties = await this.$axios
                .$delete(this.$settings.API.removeFavouriteProperty(property_id))
            ;
        }

        commit('SET', {favorite_properties});
    },
    setIsPremium({commit}, value) {
        commit('SET', {isPremiumBuyer: value});
    }
};
