export default ({ req, store }, inject) => {
    let client_session_id = null;

    if (process.server) {
        client_session_id = req.client_session_id || null;
    } else if (process.client) {

        const cookies = document.cookie || '';
        client_session_id = cookies
            .split('; ')
            .find((row) => row.startsWith('client_session_id='))
            ?.split('=')[1] || null;

        window.onNuxtReady(() => {
            client_session_id && store.dispatch('settings/initState', client_session_id);
        });
    }
};
